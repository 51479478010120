import { getResourcePolicies } from "../../../api/database_policy";

export const loadPolices = async (resource_ids, resource_type, setPolicies, setPolicy, colName) => {
  const { data, error } = await getResourcePolicies(resource_ids, resource_type);
  if (data !== null) {
    setPolicies(data.policies);
    if (colName) {
      let temp_policy = {};
      for (let key in colName) {
        temp_policy[colName[key].id] = Object.entries(data.policies)[0][1][0];
      }
      setPolicy(temp_policy);
    }
  }
};

export const handleSetPolicy = (key, selectedOption, policy, setPolicy) => {
  let tempPolicy = { ...policy };
  tempPolicy[key] = selectedOption;
  setPolicy(tempPolicy);
}
export const handleGetPolicy = (selected_key, policy) => {
  return policy[selected_key]
}