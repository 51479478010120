import { Skeleton } from '@mui/material'
import React from 'react'

const MFAListSkeleton = () => {
    return (
        <>
            <div className="mfa-methods-list">
                <div className="row justify-content-around h-75">
                    <Skeleton variant="rectangular" animation="wave" height={500} sx={{ width: "100%", height: "100%" }} />
                </div>
            </div>
        </>
    )
}

export default MFAListSkeleton
