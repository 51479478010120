import React from "react";
import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch } from "react-redux";
import {changeTitle} from "../../../state/slices/header";
import images from "../../../utils/images.js";

function Groups(props) {
  const { isEndUser, title } = props;
  const dispatch = useDispatch();
  const handleTitle = async () => {
    dispatch(changeTitle("Groups"));
}
  return (
    <Accordion.Item eventKey="Groups"  className={title === "Groups"?"sidebar-button-border ": ""}>
      <Accordion.Header >
      <img
              className="mx-3 filter-active"
              src={require("./icons/groups.svg").default}
              alt="groups-icon"
              height="22px"
              width="22px"
            ></img>
        Groups
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup variant="flush" className="sidebar-list">
          <ListGroup.Item className="py-0 pe-0 ps-2 ">
            <NavLink
              to="/dashboard/groups"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
               <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Group list</div>
            </NavLink>
          </ListGroup.Item>
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default Groups;
