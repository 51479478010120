import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { getLoginSuccessData } from "../../../../api/plot";
import { Doughnut } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import useWindowDimensions from "../../../../utils/getHeightWidth";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

export const LoginSuccessChart = () => {
  const {height, width} = useWindowDimensions();
  const [successCount, setSuccessCount] = useState(null);
  const [failureCount, setFailureCount] = useState(null);

  const getLoginData = async () => {
    const response = await getLoginSuccessData();
    if (response.data !== null) {
      response.data.total_success_login !== 0
        ? setSuccessCount(response.data.total_success_login)
        : setSuccessCount(0.1);
      response.data.total_failed_login !== 0
        ? setFailureCount(response.data.total_failed_login)
        : setFailureCount(0.1);
    }
  };

  useEffect(() => {
    getLoginData();
  }, []);

  const data = {
    labels: ["Success", "Failure"],
    datasets: [
      successCount &&
        failureCount && {
          data: [parseInt(successCount), parseInt(failureCount)],
          backgroundColor: ["#5177FF", "#B1C2FE"],
          borderWidth: 1,
          borderRadius: 10,
        },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    cutout: "75%",
    rotation: -90,
    circumference: 180,
  };

  return (
    <Row className="h-100 d-flex justify-content-center position-relative">
      <div className={"h-20 d-flex justify-content-center flex-column fw-600 ff-poppins text-center card-heading "+ (height <= 786 ? " fs-14px":" fs-18px")}>
        <div className="h-100 d-flex justify-content-center align-items-center text-break">Login Success/Failure</div>
        <div className="mt-auto border-bottom"></div>
      </div>
      <div className="h-80  d-flex justify-content-center ">
        {successCount !== null && failureCount !== null && (
          <Doughnut data={data} options={options} />
        )}
      </div>

      <div
        className="position-absolute text-center text-break w-50"
        
        style={height <=786 ?  {
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }:{
          top: "68%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={"ff-poopins fw-600  text-secondary "+ (height <=786 ? " fs-12px ": " fs-14px ")}>Total</div>
        <div className={"ff-poopins fw-500 "+  (height <=786 ? " fs20px ": " fs-26px ")}>
          {Math.floor(successCount + failureCount)}
        </div>
      </div>
    </Row>
  );
};
