import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  allocateGroupsToUser,
  notAllocatedUserGroupsList,
} from "../../../../api/users";
import assetHeadingIcon from "../../../../images/User_heading_icon.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import { changeTitle } from "../../../../state/slices/header";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { getUserDetails } from "../../../../api/users";
import { Slide, Fade } from "@mui/material";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import GetCapabilities from "../../../../utils/getCapabilities";

const ChangeActionComponent = ({ user_id }) => {
  const navigate = useNavigate();
  const ViewAssignedGroups = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });

  return (
    <Stack direction="row" spacing={2} className="py-3">
      <ViewAssignedGroups
        onClick={() => {
          navigate(`/dashboard/users/usersList/view-groups/${user_id}`);
        }}
        variant="outlined"
      >
        View Assigned Groups
      </ViewAssignedGroups>
    </Stack>
  );
};

const AllocateUserToGroup = () => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [userName, setUsername] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_AllocateUserToGroup") !== null
      ? localStorage.getItem("rowsPerPage_AllocateUserToGroup")
      : 5
  );
  const [groupsList, setGroupsList] = useState([]);
  const [groups, setGroups] = useState(null);
  const [totalApps, setTotalApps] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openAllocateUser, setOpenAllocateUser] = useState(false);
  const [toBeAllocate, setToBeAllocate] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [colName, setColName] = useState(null);
  const pages_url = "dashboard/userGroups";
  const customizeColumnDict = { ID: true, "Display Name": true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const title_heading = useSelector((state) => state.header.title);
  const AppColumns = [
    { label: "ID", value: "group_id" },
    { label: "Name", value: "group_name" },
    { label: "Display Name", value: "group_display_name" },
  ];

  const actionOptions = [{ label: "Allocate", value: "allocate" }];
  const fetchUsersData = async () => {
    const { data, error } = await getUserDetails(user_id);
    if (data != null) {
      setUsername(data.username);
    }
    if (error != null) {
    }
  };
  useEffect(() => {
    dispatch(changeTitle("AssignGroups"));
    fetchUsersData();
  }, []);

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    getUsersList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, groups]);

  const getUsersList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await notAllocatedUserGroupsList({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      user_id: user_id,
      type: "groups",
    });
    if (data !== null) {
      setGroupsList(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalApps(data.count);
      setFilteredData(data.results);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getUsersList({ page: 1, rowsPerPage, search, filterBy });
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = groupsList.filter((object) => {
      if (!filterBy) {
        return (
          object.group_name.toLowerCase().match(s.toLowerCase()) ||
          object.group_display_name.toLowerCase().match(s.toLowerCase()) ||
          object.group_id.toString().toLowerCase().match(s.toLowerCase())
        );
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_AllocateUserToGroup", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "allocate") {
      handleAllocate(null);
    }
  };

  const handleAllocate = async (row) => {
    let rowsToAllocate = [];
    if (row) {
      rowsToAllocate = row;
    } else {
      rowsToAllocate = selectedRows;
    }
    setToBeAllocate(rowsToAllocate);
    let temp = [];
    temp = rowsToAllocate.map((item) => item.group_name);
    setColName(temp);
    setOpenAllocateUser((o) => !o);
  };

  const handleAllocateAction = async () => {
    let ids = toBeAllocate.map((item) => item.group_id);
    let count = ids.length;
    const { data, error } = await allocateGroupsToUser({
      ids: ids,
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      user_id: user_id,
      type: "groups",
    });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalApps % rowsPerPage === count || rowsPerPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setGroups(Object.create(null));
      setOpenAllocateUser((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.group_id,
      grow: 1,
      omit: !checkedStateDict["ID"],
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.group_name,
      sortable: true,
    },
    {
      name: "Display Name",
      selector: (row) => row.group_display_name,
      omit: !checkedStateDict["Display Name"],
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) =>
        isCapable &&
        isCapable.groups.assign_users_to_group && (
          <>
            <p
              className="myassetslink"
              onClick={async () => {
                handleAllocate([row]);
              }}
            >
              <AiOutlineUserAdd></AiOutlineUserAdd>Allocate
            </p>
          </>
        ),
      middle: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "AssignGroups":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/User_heading_icon.svg")
                              .default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Assign Groups
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName && (
                  <CustomModal
                    open={openAllocateUser}
                    handleClose={() => {
                      setOpenAllocateUser((o) => !o);
                    }}
                  >
                    <ShowAlert
                      setOpenAlert={setOpenAllocateUser}
                      colName={colName}
                      handleAlertAction={handleAllocateAction}
                      alertMsg={`Below Groups will be allocated for User:`}
                      entity_name={userName}
                      headingMsg="Allocate Groups"
                    />
                  </CustomModal>
                )}

                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`User : ${userName}`}
                        dataTableHeadingIcon={assetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalApps}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    actions={<ChangeActionComponent user_id={user_id} />}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="group"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={AppColumns}
                        actionOptions={actionOptions}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        setCheckedStateDict={setCheckedStateDict}
                        checkedStateDict={checkedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default AllocateUserToGroup;
