import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DropDownAction from "../../../common/DropDownAction/DropDownAction";
import AllocateAppToGroup from "./AllocateAppToGroup";
import { getMyAppDetail } from "../../../../api/apps";
import AllocateAppToUser from "./AllocateAppToUser";
import AllocateAppToRole from "./AllocateAppToRole";

const MainAllocateApps = () => {
  const { app_id } = useParams();
  const [tabValue, setTabValue] = useState("users");
  const [appName, setAppName] = useState("");
  const actionOptions = [
    { label: "Users", value: "users" },
    { label: "Groups", value: "groups" },
    { label: "Roles", value: "roles" },
  ];

  const fetchAppData = async () => {
    const { data, error } = await getMyAppDetail({ app_id: app_id });
    if (data != null) {
      setAppName(data.app.app_name);
    }
    if (error != null) {
    }
  };

  useEffect(() => {
    fetchAppData();
  }, []);

  return (
    <div>
      {tabValue === "groups" && (
        <AllocateAppToGroup
          appName={appName}
          ActionComponent={
            <DropDownAction
              setTabValue={setTabValue}
              tabValue={tabValue}
              actionOptions={actionOptions}
            />
          }
        />
      )}
      {tabValue === "users" && (
        <AllocateAppToUser
          appName={appName}
          ActionComponent={
            <DropDownAction
              setTabValue={setTabValue}
              tabValue={tabValue}
              actionOptions={actionOptions}
            />
          }
        />
      )}
      {tabValue === "roles" && (
        <AllocateAppToRole
          appName={appName}
          ActionComponent={
            <DropDownAction
              setTabValue={setTabValue}
              tabValue={tabValue}
              actionOptions={actionOptions}
            />
          }
        />
      )}
    </div>
  );
};

export default MainAllocateApps;
