import Sidebar from "../Sidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { Outlet } from "react-router-dom";
import "./Dashboard.scss";
import AuditSidebar from "../Sidebar/AuditSidebar";

function Dashboard(props) {
  const { width } = useWindowDimensions();
  const { isEndUser } = props;
  const { isAuditDashboard } = props;
  return (
    <Container
      fluid
      className={
        width >= 768
          ? "position-fixed px-0 w-100 h-100 "
          : "position-absolute h-100 w-100"
      }
      style={{ zIndex: 1 }}
    >
      <Row className="w-100 h-100 mx-0 ">
        <Col lg={2} md={3} className="position-relative px-0 h-100 pt-81px">
          <div className={width >= 768? "h-100 overflow-auto sidebar-border-end" : "h-100 overflow-auto"}>
          {isAuditDashboard==false && <Sidebar isEndUser={isEndUser} />}
          {isAuditDashboard==true && <AuditSidebar isEndUser={isEndUser} ></AuditSidebar>}
          </div>
        </Col>

        <Col lg={10} md={9} className="h-100 px-0 bg-home pt-81px overflow-auto">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
