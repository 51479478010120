import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import DropDownAction from "../../../common/DropDownAction/DropDownAction";
import DeAllocateAppFromGroup from "./DeAllocateAppFromGroup";
import {getMyAppDetail} from "../../../../api/apps";
import DeAllocateAppFromUser from "./DeAllocateAppFromUser";
import DeAllocateAppFromRole from "./DeAllocateAppFromRole";

const MainDeAllocateApps = () => {
    const {app_id} = useParams();
    const [tabValue, setTabValue] = useState("users")
    const [appName, setAppName] = useState("");
    const actionOptions = [
        {label: "Users", value: "users"},
        {label: "Groups", value: "groups"},
        {label: "Roles", value: "roles"},
    ]

    const fetchAppData = async () => {
        const {data, error} = await getMyAppDetail({app_id: app_id});
        if (data != null) {
            setAppName(data.app.app_name);
        }
        if (error != null) {

        }
    }

    useEffect(() => {
        fetchAppData();
    }, [])

    return (
        <div>
            {tabValue === "groups" && <DeAllocateAppFromGroup appName={appName} ActionComponent={< DropDownAction
                setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions}/>}/>}
            {tabValue === "users" && <DeAllocateAppFromUser appName={appName} ActionComponent={< DropDownAction
                setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions}/>}/>}
            {tabValue === "roles" && <DeAllocateAppFromRole appName={appName} ActionComponent={< DropDownAction
                setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions}/>}/>}
        </div>
    )
}

export default MainDeAllocateApps;