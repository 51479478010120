import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import MuiButton from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StorageIcon from "../../../images/storage_icon.svg";
import RefreshIcon from "../../../images/refresh_icon.svg";
import Googlecloud from "../../../images/google-cloud_logo.png";
import Show from "../../../images/show.png";
import Hide from "../../../images/hide.png";
import DropBox from "../../../images/dropbox_logo.png";
import S3 from "../../../images/amazon-s3_logo.png";
import MongoDb from "../../../images/mongodb.png";
import Cloudinary from "../../../images/cloudinary_logo.png";
import { useEffect, useState } from "react";
import { changeTitle } from "../../../state/slices/header";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  getStorageOptions,
  updateStorageOptions,
  getDropboxRefreshToken,
} from "../../../api/storage_config";
import {
  showError,
  showSuccess,
  showErrorMsg,
} from "../../../utils/showMessage";
import useWindowDimensions from "../../../utils/getHeightWidth";
import "./StorageConfig.scss";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../utils/transitionEffectParams";
import { Slide, Fade } from "@mui/material";
import CustomModal from "../../common/CustomModal/CustomModal";
import Button from "react-bootstrap/Button";
import CrossIcon from "../../../images/cross.svg";
import { TbFileImport } from "react-icons/tb";

const StorageConfig = () => {
  const active_switch =
    "form-control p-1 d-flex flex-row justify-content-between align-items-center active_card";
  const inactive_switch =
    "form-control p-1 d-flex flex-row justify-content-between align-items-center";
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 180 + "px";
  const [selectedOption, setSelectedOption] = useState(null);
  const [apiEnvVar, setApiEnvVar] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [cloudName, setCloudName] = useState("");
  const [storageType, setStorageType] = useState(null);
  const [regionName, setRegionName] = useState("");
  const [accessKey, setAccessKey] = useState("");
  const [connectionString, setConnectionString] = useState("");
  const [showHidden1, setShowHidden1] = useState(false);
  const [showHidden2, setShowHidden2] = useState(false);
  const [showHidden3, setShowHidden3] = useState(false);
  const [slideChecked, setSlideChecked] = useState(false);
  const [containerName, setContainerName] = useState("");
  const [oAuthCode, setOauthCode] = useState("");
  const [rediected, setRediected] = useState(false);
  const default_google_dictionary = {
    type: "",
    project_id: "",
    private_key_id: "",
    private_key: "",
    client_email: "",
    client_id: "",
    auth_uri: "",
    token_uri: "",
    auth_provider_x509_cert_url: "",
    client_x509_cert_url: "",
    universe_domain: "",
  };
  const [googleCredentials, setGoogleCredentials] = useState(default_google_dictionary);

  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const title_heading = useSelector((state) => state.header.title);
  const SaveButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    color: "#5177FF",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const ResetButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    color: "error",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const GenerateButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    color: "#5177FF",
    fontFamily: ['"DM Sans"'].join(","),
  });

  const dispatch = useDispatch();
  const fileSizeExceedMsg = "File size exceeds 2Mb";
  const notJson = "Selected file is not a json";
  const FileInvalidMsg =
    "Selected file does not contain a valid credentials data";
  const maxFileSize = 2e6;
  const Fileregex = new RegExp("[^.]+$");
  const [isJson, setIsJson] = useState(false);
  const [fileSizeExceed, setFileSizeExceed] = useState(false);
  const [buttonAble, setButtonAble] = useState(true);
  const [fileData, setFileData] = useState({});
  const [FileValidity, setFileValidity] = useState(false);
  const [validated, setValidated] = useState(false);

  const getExtension = (filename) => {
    return filename.split(".").pop();
  };

  const compareFileData = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    return (
      keys1.length === keys2.length &&
      keys1.every((key) => obj2.hasOwnProperty(key))
    );
  };

  const handleResetConfiguration = () => {
    setApiKey("");
    setSecretKey("");
    setCloudName("");
    setRegionName("");
    setAccessKey("");
    setConnectionString("");
    setOauthCode("");
    setContainerName("");
    setApiEnvVar("");
    setRediected(false);
    setGoogleCredentials(default_google_dictionary)
    setSelectedOption("local_server");
    resetOptions();
  };

  const resetOptions = async () => {
    if (selectedOption !== "local_server") {
      const { data, error } = await updateStorageOptions({
        storage_type: storageType,
        ...{ api_key: "" },
        ...{ api_env_var: ""},
        ...{ cloud_name: "" },
        ...{ connection_string: "" },
        ...{ container_name: "" },
        ...{ access_key: "" },
        ...{ secret_key: "" },
        ...{ region_name: "" },
        ...{
          google_cloud_credentials:
            selectedOption === "GoogleCloud"
              ? JSON.stringify(default_google_dictionary)
              : null,
        },
      });
      if (data !== null) {
        showSuccess(data.message);
      }
      if (error !== null) {
        showError(error);
      }
    }
    getStorageOption("local_server");
  };

  const handleFileSelect = (event) => {
    let extension = event.target.files[0].name.toLowerCase().match(Fileregex);
    if (getExtension(extension[0]) === "json") {
      if (event.target.files[0].size > maxFileSize) {
        setIsJson(false);
        showErrorMsg("File size exceeds maximum");
        setButtonAble(true);
        setFileSizeExceed(true);
      } else {
        const fileReader = new FileReader();
        fileReader.readAsText(event.target.files[0], "UTF-8");
        fileReader.onload = (event) => {
          var obj = JSON.parse(event.target.result);
          if (compareFileData(obj, default_google_dictionary)) {
            setFileData(obj);
            setButtonAble(false);
            setFileValidity(false);
            setFileSizeExceed(false);
            setIsJson(false);
          } else {
            showErrorMsg(
              "Selected file does not contain valid credentials data"
            );
            setFileValidity(true);
            setButtonAble(true);
            setIsJson(false);
          }
        };
      }
    } else {
      showErrorMsg("Selected file is not a valid json file");
      setFileSizeExceed(false);
      setFileValidity(false);
      setButtonAble(true);
      setIsJson(true);
    }
  };

  const handleClose = () => {
    setFileSizeExceed(false);
    setFileValidity(false);
    setIsJson(false);
    setOpenFileImport((o) => !o);
    setButtonAble(true);
  };

  const handleFileSubmit = (event) => {
    setGoogleCredentials(fileData);
    showSuccess("Credentials imported successfully");
    handleClose();
  };

  const updateToLocal = async () =>{
    const { data, error } = await getStorageOptions("local_server");
    if(data !== null){
      showSuccess("Storage options updated successfully");
    }
    else{
      showError(error)
    }
  }

  const updateOption = async (event) => {
    const form = event.currentTarget;
    event && event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    }
    else{
      if (selectedOption === "local_server"){
        updateToLocal()
      }
      else {
        const { data, error } = await updateStorageOptions({
          storage_type: storageType,
          ...{ api_key: apiKey },
          ...{ api_env_var: apiEnvVar },
          ...{ cloud_name: cloudName },
          ...{ container_name: containerName },
          ...{ connection_string: connectionString },
          ...{ access_key: accessKey },
          ...{ secret_key: secretKey },
          ...{ region_name: regionName },
          ...{
            google_cloud_credentials:
              selectedOption === "GoogleCloud"
                ? JSON.stringify(googleCredentials)
                : null,
          },
        });
        if (data !== null) {
          showSuccess(data.message);
        }
        if (error !== null) {
          showError(error);
        }
      }
    }
    
  };
  const [openFileImport, setOpenFileImport] = useState(false);

  const handleSwitch = (event) => {
    if (event !== selectedOption) {
      getStorageOption(event);
    } else {
      getStorageOption("local_server");
    }
  };
  const getStorageOption = async (event) => {
    setValidated(false)
    const { data, error } = await getStorageOptions(event);
    if (data !== null) {
      setSelectedOption(data.selected_storage_option);
      if (data.storage_configuration !== null) {
        setApiEnvVar(data.storage_configuration.api_env_var);
        setApiKey(data.storage_configuration.api_key);
        setSecretKey(data.storage_configuration.secret_key);
        setCloudName(data.storage_configuration.cloud_name);
        setStorageType(data.storage_configuration.storage_type);
        setRegionName(data.storage_configuration.region_name);
        setAccessKey(data.storage_configuration.access_key);
        setConnectionString(data.storage_configuration.connection_string)
        setContainerName(data.storage_configuration.container_name);
        data.storage_configuration.google_cloud_credentials &&
          setGoogleCredentials(
            (JSON.parse(data.storage_configuration.google_cloud_credentials !==undefined ||  JSON.parse(data.storage_configuration.google_cloud_credentials !==null) )? JSON.parse(data.storage_configuration.google_cloud_credentials) : default_google_dictionary)
          );
        setShowHidden1(false);
        setShowHidden2(false);
        setShowHidden3(false);
      }
      if (
        data.storage_configuration === null &&
        data.selected_storage_option !== null
      ) {
        setApiKey("");
        setSecretKey("");
        setCloudName("");
        setRegionName("");
        setAccessKey("");
        setConnectionString("")
        setOauthCode("");
        setContainerName("");
        setApiEnvVar("");
        setRediected(false);
        selectedOption === "GoogleCloud"
          ? setGoogleCredentials(default_google_dictionary)
          : setGoogleCredentials(default_google_dictionary);setStorageType(null);
        setShowHidden1(false);
        setShowHidden2(false);
        setShowHidden3(false);
      }
    } else showError(error);
  };

  const getAuthCode = () => {
    try {
      window.open(
        "https://www.dropbox.com/oauth2/authorize?response_type=code&client_id=" +
          apiKey +
          "&token_access_type=offline",
        "newwindow",
        `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=800,
      height=700,
      top=` +
          (window.innerHeight - 700) / 2 +
          `, 
      left=` +
          (window.innerWidth - 800) / 2
      );
      setRediected(true);
    } catch (e) {
      showErrorMsg(e);
    }
  };

  const getRefreshToken = async () => {
    const { data, error } = await getDropboxRefreshToken(
      oAuthCode,
      apiKey,
      secretKey
    );
    if (data !== null) {
      setAccessKey(data.refresh_token);
      setOauthCode("");
      showSuccess("Refresh token feched successfully");
    } else {
      setOauthCode("");
      showErrorMsg(error.error + " : " + error.error_description);
    }
  };

  useEffect(() => {
    dispatch(changeTitle("Settings"));
  }, []);

  useEffect(() => {
    getStorageOption("");
  }, []);

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Settings":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../images/settings-icon.svg").default
                          }
                          alt="settings icon"
                        />{" "}
                        &nbsp; Settings
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white overflow-auto w-100"
                  style={{ height: `${datatable_height}` }}
                >
                  <Form noValidate validated={validated} autocomplete="off" onSubmit={updateOption}>
                    <div className="heading_title storage_header p-0">
                      <div className="d-flex flex-row justify-content-between align-items-center py-3">
                        <div className="abc d-flex align-items-center">
                          <img
                            src={
                              require("../../../images/settings-icon.svg")
                                .default
                            }
                            alt="settings icon"
                          />
                          <h2 className="main-heading d-inline ms-2">
                            Storage Configuration
                          </h2>
                        </div><div>
                        <ResetButton
                          variant="outlined"
                          className="me-3"
                          color="error"
                          onClick={()=>handleResetConfiguration()}
                        >
                          Reset
                        </ResetButton>
                        <SaveButton
                          type="submit"
                          variant="outlined"
                          disabled={selectedOption === "DropBox" && (accessKey === "" || accessKey === null) ? true: false}
                        >
                          Save
                        </SaveButton>
                        </div>
                        
                        </div>
                      </div>
                      <div className="select-storage storage_header border-top-0 p-0">
                        <div className="d-flex flex-row justify-content-between align-items-center py-3">
                          <h2 className="main-heading sub_heading d-inline ff-poppins">
                            Select Storage
                          </h2>
                        </div>
                        <Row className="pb-2">
                          <Col className="pb-4">
                            <div
                              className={
                                selectedOption === "GoogleCloud"
                                  ? active_switch
                                  : inactive_switch
                              }
                            >
                              <div>
                                <Image
                                  src={Googlecloud}
                                  height="35px"
                                  className="px-2"
                                ></Image>
                                <span className="ff-poppins fs-12px">
                                  Google Cloud
                                </span>
                              </div>
                              <div>
                                <Form.Check
                                  type="switch"
                                  checked={
                                    selectedOption === "GoogleCloud"
                                      ? true
                                      : false
                                  }
                                  id="GoogleCloud"
                                  onChange={(e) => handleSwitch(e.target.id)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col  className="pb-4">
                            <div
                              className={
                                selectedOption === "S3"
                                  ? active_switch
                                  : inactive_switch
                              }
                            >
                              <div>
                                <Image
                                  src={S3}
                                  height="35px"
                                  className="px-2"
                                ></Image>
                                <span className="ff-poppins fs-12px">AWS S3</span>
                              </div>
                              <div>
                                <Form.Check
                                  type="switch"
                                  checked={selectedOption === "S3" ? true : false}
                                  id="S3"
                                  onChange={(e) => handleSwitch(e.target.id)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col  className="pb-4">
                            <div
                              className={
                                selectedOption === "MongoDb"
                                  ? active_switch
                                  : inactive_switch
                              }
                            >
                              <div>
                                <Image
                                  src={MongoDb}
                                  height="35px"
                                  className="px-2"
                                ></Image>
                                <span className="ff-poppins fs-12px">Mongo Db</span>
                              </div>
                              <div>
                                <Form.Check
                                  type="switch"
                                  checked={selectedOption === "MongoDb" ? true : false}
                                  id="MongoDb"
                                  onChange={(e) => handleSwitch(e.target.id)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col  className="pb-4">
                            <div
                              className={
                                selectedOption === "DropBox"
                                  ? active_switch
                                  : inactive_switch
                              }
                            >
                              <div>
                                <Image
                                  src={DropBox}
                                  height="35px"
                                  className="px-2"
                                ></Image>
                                <span className="ff-poppins fs-12px">
                                  DropBox
                                </span>
                              </div>
                              <div>
                                <Form.Check
                                  type="switch"
                                  checked={
                                    selectedOption === "DropBox" ? true : false
                                  }
                                  id="DropBox"
                                  onChange={(e) => handleSwitch(e.target.id)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col  className="pb-4">
                            <div
                              className={
                                selectedOption === "Cloudinary"
                                  ? active_switch
                                  : inactive_switch
                              }
                            >
                              <div>
                                <Image
                                  src={Cloudinary}
                                  height="35px"
                                  className="px-2"
                                ></Image>
                                <span className="ff-poppins fs-12px">
                                  Cloudinary
                                </span>
                              </div>
                              <div>
                                <Form.Check
                                  type="switch"
                                  checked={
                                    selectedOption === "Cloudinary" ? true : false
                                  }
                                  id="Cloudinary"
                                  onChange={(e) => handleSwitch(e.target.id)}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {selectedOption === "GoogleCloud" && (
                      <div className="px-5 pt-4 w-100">
                        <h2 className="main-heading sub_heading ff-poppins d-flex justify-content-between w-100">
                          Configuration for Google Cloud
                          <Button
                            variant="primary"
                            onClick={() => setOpenFileImport(true)}
                          >
                            <TbFileImport size={22} /> Import From File
                          </Button>
                          <CustomModal
                            open={openFileImport}
                            width="40% !important"
                            >
                            <Form>
                              <div className="d-flex justify-content-between align-items-center  mb-2">
                                <h2 className="main_content_heading">
                                  Import Google cloud storage credentials
                                </h2>
                                <img
                                  src={CrossIcon}
                                  alt="close"
                                  className="cursor_pointer"
                                  onClick={handleClose}
                                />
                              </div>
                              <div className="py-2">
                                <Form.Group
                                  controlId="formFile"
                                  className="mb-3"
                                >
                                  <Form.Label>Select JSON file </Form.Label> 
                                  <Form.Control
                                    onChange={handleFileSelect}
                                    type="file"
                                    accept=".json"
                                  />
                                  <div class="ff-poppins fs-12px d-flex bd-highlight">
                                    <div class="me-auto p-1 bd-highlight text-danger">
                                      {fileSizeExceed === true
                                        ? fileSizeExceedMsg
                                        : ""}
                                      {isJson === true ? notJson : ""}
                                      {FileValidity == true
                                        ? FileInvalidMsg
                                        : ""}
                                    </div>
                                    <div class="p-1 bd-highlight">
                                      Max size : 2Mb
                                    </div>
                                  </div>
                                </Form.Group>
                              </div>
                              <Row className="mb-3 justify-content-end">
                                <Col sm="auto">
                                  <Button
                                    variant="light"
                                    as="input"
                                    type="button"
                                    className="user_cancel_button"
                                    value="Cancel"
                                    onClick={handleClose}
                                  />
                                </Col>

                                <Col sm="auto" className="me-sm-2 p-0">
                                  <Button
                                    as="input"
                                    type="button"
                                    onClick={handleFileSubmit}
                                    value="Import"
                                    className="user_submit_button"
                                    disabled={buttonAble}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </CustomModal>
                        </h2>
                        <Row className="py-4">
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="type"
                            >
                              <Form.Label>Account Type <span className="text-danger">*</span> </Form.Label>
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={googleCredentials.type}
                                onChange={(event) =>
                                  setGoogleCredentials((prevDict) => ({
                                    ...prevDict,
                                    ["type"]: event.target.value,
                                  }))
                                }
                                placeholder="Enter account type. eg. service_account"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter account type
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="bucketName"
                            >
                              <Form.Label>Bucket Name<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={containerName}
                                onChange={(event) =>
                                  setContainerName(event.target.value)
                                }
                                placeholder="Enter project id"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter bucket name
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="projectId"
                            >
                              <Form.Label>Project Id<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={googleCredentials.project_id}
                                onChange={(event) =>
                                  setGoogleCredentials((prevDict) => ({
                                    ...prevDict,
                                    ["project_id"]: event.target.value,
                                  }))
                                }
                                placeholder="Enter project id"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter project id
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="privateKeyId"
                            >
                              <Form.Label>Private Key Id<span className="text-danger">*</span> </Form.Label> 
                              <InputGroup
                                className="pb-2 form_label"
                                controlId="privateKeyId1"
                              >
                                <Form.Control
                                  className=" user_input_field border-end-0" required
                                  type={
                                    showHidden1 === false ? "password" : "text"
                                  }
                                  value={googleCredentials.private_key_id}
                                  onChange={(event) =>
                                    setGoogleCredentials((prevDict) => ({
                                      ...prevDict,
                                      ["private_key_id"]: event.target.value,
                                    }))
                                  }
                                  placeholder="Enter private key id"
                                />
                                <InputGroup.Text
                                  id="privateKeyId"
                                  className="bg-white my-input-group cursor_pointer"
                                  onClick={() => setShowHidden1(!showHidden1)}
                                >
                                  {showHidden1 === false ? (
                                    <Image
                                      src={Show}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  ) : (
                                    <Image
                                      src={Hide}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  Please enter private key id
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="privateKey"
                            >
                              <Form.Label>Private Key <span className="text-danger">*</span> </Form.Label> 
                              <InputGroup
                                className="pb-2 form_label"
                                controlId="privateKey"
                              >
                                <Form.Control
                                  className=" user_input_field border-end-0" required
                                  type={
                                    showHidden2 === false ? "password" : "text"
                                  }
                                  value={googleCredentials.private_key}
                                  onChange={(event) =>
                                    setGoogleCredentials((prevDict) => ({
                                      ...prevDict,
                                      ["private_key"]: event.target.value,
                                    }))
                                  }
                                  placeholder="Enter private key"
                                />
                                <InputGroup.Text
                                  id="privateKey"
                                  className="bg-white my-input-group cursor_pointer"
                                  onClick={() => setShowHidden2(!showHidden2)}
                                >
                                  {showHidden1 === false ? (
                                    <Image
                                      src={Show}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  ) : (
                                    <Image
                                      src={Hide}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  Please enter private key
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="clientEmail"
                            >
                              <Form.Label>Client Email<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="email"
                                value={googleCredentials.client_email}
                                onChange={(event) =>
                                  setGoogleCredentials((prevDict) => ({
                                    ...prevDict,
                                    ["client_email"]: event.target.value,
                                  }))
                                }
                                placeholder="Enter client email"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter client email
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="clientId"
                            >
                              <Form.Label>Client Id<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={googleCredentials.client_id}
                                onChange={(event) =>
                                  setGoogleCredentials((prevDict) => ({
                                    ...prevDict,
                                    ["client_id"]: event.target.value,
                                  }))
                                }
                                placeholder="Enter client id"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter client id
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="client_x509_cert_url"
                            >
                              <Form.Label>Client Cert Url<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={googleCredentials.client_x509_cert_url}
                                onChange={(event) =>
                                  setGoogleCredentials((prevDict) => ({
                                    ...prevDict,
                                    ["client_x509_cert_url"]:
                                      event.target.value,
                                  }))
                                }
                                placeholder="Enter client cert url"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter Client Cert Url
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="universeDomain"
                            >
                              <Form.Label>Universe Domain<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={googleCredentials.universe_domain}
                                onChange={(event) =>
                                  setGoogleCredentials((prevDict) => ({
                                    ...prevDict,
                                    ["universe_domain"]: event.target.value,
                                  }))
                                }
                                placeholder="Enter Universe Domain"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter universe domain
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="auth_uri"
                            >
                              <Form.Label>Auth uri<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={googleCredentials.auth_uri}
                                onChange={(event) =>
                                  setGoogleCredentials((prevDict) => ({
                                    ...prevDict,
                                    ["auth_uri"]: event.target.value,
                                  }))
                                }
                                placeholder="Enter auth uri"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter auth uri
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="tokenUri"
                            >
                              <Form.Label>Token Uri<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={googleCredentials.token_uri}
                                onChange={(event) =>
                                  setGoogleCredentials((prevDict) => ({
                                    ...prevDict,
                                    ["token_uri"]: event.target.value,
                                  }))
                                }
                                placeholder="Enter token uri"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter token uri
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="auth_provider_x509_cert_url"
                            >
                              <Form.Label>Auth Provider Cert Url<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={
                                  googleCredentials.auth_provider_x509_cert_url
                                }
                                onChange={(event) =>
                                  setGoogleCredentials((prevDict) => ({
                                    ...prevDict,
                                    ["auth_provider_x509_cert_url"]:
                                      event.target.value,
                                  }))
                                }
                                placeholder="Enter Auth Provider Cert Url"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter auth provider cert url
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {selectedOption === "Cloudinary" && (
                      <div className="px-5 pt-4">
                        <h2 className="main-heading sub_heading d-inline ff-poppins">
                          Configuration for Cloudinary
                        </h2>
                        <Row className="py-4">
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="apiKey"
                            >
                              <Form.Label>Api Key<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={apiKey}
                                onChange={(event) =>
                                  setApiKey(event.target.value)
                                }
                                placeholder="Enter api key"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter api key
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="apiSecret"
                            >
                              <Form.Label>Api Secret<span className="text-danger">*</span> </Form.Label> 
                              <InputGroup
                                className="pb-2 form_label"
                                controlId="apiSecret"
                              >
                                <Form.Control
                                  className=" user_input_field border-end-0" required
                                  type={
                                    showHidden1 === false ? "password" : "text"
                                  }
                                  value={secretKey}
                                  onChange={(event) =>
                                    setSecretKey(event.target.value)
                                  }
                                  placeholder="Enter api secret"
                                />
                                <InputGroup.Text
                                  id="apiSecret"
                                  className="bg-white my-input-group cursor_pointer"
                                  onClick={() => setShowHidden1(!showHidden1)}
                                >
                                  {showHidden1 === false ? (
                                    <Image
                                      src={Show}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  ) : (
                                    <Image
                                      src={Hide}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  Please enter api secret
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="apiEnvVar"
                            >
                              <Form.Label>Api Environment Variable<span className="text-danger">*</span> </Form.Label> 
                              <InputGroup
                                className="pb-2 form_label"
                                controlId="apiEnvVar"
                              >
                                <Form.Control
                                  className=" user_input_field border-end-0" required
                                  type={
                                    showHidden2 === false ? "password" : "text"
                                  }
                                  value={apiEnvVar}
                                  onChange={(event) =>
                                    setApiEnvVar(event.target.value)
                                  }
                                  placeholder="Enter api environment variable"
                                />
                                <InputGroup.Text
                                  id="apiSecret"
                                  className="bg-white my-input-group cursor_pointer"
                                  onClick={() => setShowHidden2(!showHidden2)}
                                >
                                  {showHidden2 === false ? (
                                    <Image
                                      src={Show}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  ) : (
                                    <Image
                                      src={Hide}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  Please enter api environment variable
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="cloudName"
                            >
                              <Form.Label>Cloud Name<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={cloudName}
                                onChange={(event) =>
                                  setCloudName(event.target.value)
                                }
                                placeholder="Enter cloud name"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter cloud name
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {selectedOption === "S3" && (
                      <div className="px-5 pt-4">
                        <h2 className="main-heading sub_heading d-inline ff-poppins">
                          Configuration for AWS S3
                        </h2>
                        <Row className="py-4">
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="bucketName"
                            >
                              <Form.Label>AWS bucket name<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={cloudName}
                                onChange={(event) =>
                                  setCloudName(event.target.value)
                                }
                                placeholder="Enter bucket name"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter aws bucket name
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="regionName"
                            >
                              <Form.Label>Region Name<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={regionName}
                                onChange={(event) =>
                                  setRegionName(event.target.value)
                                }
                                placeholder="Enter region name"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter region name
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="awsSecretKey"
                            >
                              <Form.Label>AWS secret key<span className="text-danger">*</span> </Form.Label> 
                              <InputGroup
                                className="pb-2 form_label"
                                controlId="apiSecret"
                              >
                                <Form.Control
                                  className=" user_input_field border-end-0" required
                                  type={
                                    showHidden1 === false ? "password" : "text"
                                  }
                                  value={secretKey}
                                  onChange={(event) =>
                                    setSecretKey(event.target.value)
                                  }
                                  placeholder="Enter aws secret key"
                                />
                                <InputGroup.Text
                                  id="apiSecret"
                                  className="bg-white my-input-group cursor_pointer"
                                  onClick={() => setShowHidden1(!showHidden1)}
                                >
                                  {showHidden1 === false ? (
                                    <Image
                                      src={Show}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  ) : (
                                    <Image
                                      src={Hide}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  Please enter aws secret key
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="awsAccessKey"
                            >
                              <Form.Label>AWS access Key<span className="text-danger">*</span> </Form.Label> 
                              <InputGroup
                                className="pb-2 form_label"
                                controlId="apiSecret"
                              >
                                <Form.Control
                                  className=" user_input_field border-end-0" required
                                  type={
                                    showHidden2 === false ? "password" : "text"
                                  }
                                  value={accessKey}
                                  onChange={(event) =>
                                    setAccessKey(event.target.value)
                                  }
                                  placeholder="Enter aws access key"
                                />
                                <InputGroup.Text
                                  id="apiSecret"
                                  className="bg-white my-input-group cursor_pointer"
                                  onClick={() => setShowHidden2(!showHidden2)}
                                >
                                  {showHidden2 === false ? (
                                    <Image
                                      src={Show}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  ) : (
                                    <Image
                                      src={Hide}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  Please enter aws access key
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {selectedOption === "MongoDb" && (
                      <div className="px-5 pt-4">
                        <h2 className="main-heading sub_heading d-inline ff-poppins">
                          Configuration for Mongo Db
                        </h2>
                        <Row className="py-4">
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="authDb"
                            >
                              <Form.Label>Authentication Database<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={cloudName}
                                onChange={(event) =>
                                  setCloudName(event.target.value)
                                }
                                placeholder="Enter auth db"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter auth db
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="connecctionString"
                            >
                              <Form.Label>Connection string / URI<span className="text-danger">*</span> </Form.Label> 
                              <InputGroup
                                className="pb-2 form_label"
                                controlId="connectionString"
                              >
                                <Form.Control
                                  className=" user_input_field border-end-0" required
                                  type={
                                    showHidden1 === false ? "password" : "text"
                                  }
                                  value={connectionString}
                                  onChange={(event) =>
                                    setConnectionString(event.target.value)
                                  }
                                  placeholder="Enter connection string / uri"
                                />
                                <InputGroup.Text
                                  id="connectionString"
                                  className="bg-white my-input-group cursor_pointer"
                                  onClick={() => setShowHidden1(!showHidden1)}
                                >
                                  {showHidden1 === false ? (
                                    <Image
                                      src={Show}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  ) : (
                                    <Image
                                      src={Hide}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  Please enter connection string / uri
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {selectedOption === "DropBox" && (
                      <div className="px-5 pt-4">
                        <h2 className="main-heading sub_heading d-inline ff-poppins">
                          Configuration for DropBox
                        </h2>
                        <Row className="py-4">
                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="apiKey"
                            >
                              <Form.Label>App Key<span className="text-danger">*</span> </Form.Label> 
                              <Form.Control
                                className=" user_input_field" required
                                type="text"
                                value={ apiKey !== null ? apiKey:""}
                                onChange={(event) =>
                                  setApiKey(event.target.value)
                                }
                                placeholder="Enter app key"
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please enter app key
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="appSecret"
                            >

                              <Form.Label>App Secret<span className="text-danger">*</span> </Form.Label> 
                              <InputGroup
                                className="pb-2 form_label"
                                controlId="appSecret"
                              >
                                <Form.Control
                                  className=" user_input_field border-end-0" required
                                  type={
                                    showHidden1 === false ? "password" : "text"
                                  }
                                  value={secretKey !== null ? secretKey:""}
                                  onChange={(event) =>
                                    setSecretKey(event.target.value)
                                  }
                                  placeholder="Enter app secret"
                                />
                                <InputGroup.Text
                                  id="appSecret"
                                  className="bg-white my-input-group cursor_pointer"
                                  onClick={() => setShowHidden1(!showHidden1)}
                                >
                                  {showHidden1 === false ? (
                                    <Image
                                      src={Show}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  ) : (
                                    <Image
                                      src={Hide}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  Please enter app secret
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                         {accessKey && accessKey.length !== 0 && <Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="refreshToken"
                            >
                              <Form.Label>Refersh Token<span className="text-danger">*</span> </Form.Label> 
                              <InputGroup
                                className="pb-2 form_label"
                                controlId="refresToken"
                              >
                                <Form.Control
                                  disabled
                                  className=" user_input_field border-end-0" required
                                  type={
                                    showHidden2 === false ? "password" : "text"
                                  }
                                  value={accessKey}
                                  onChange={(event) =>
                                    setAccessKey(event.target.value)
                                  }
                                  placeholder="Enter refresh token"
                                />
                                <InputGroup.Text
                                  id="refreshToken"
                                  className="bg-white my-input-group cursor_pointer"
                                  onClick={() => setShowHidden2(!showHidden2)}
                                >
                                  {showHidden2 === false ? (
                                    <Image
                                      src={Show}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  ) : (
                                    <Image
                                      src={Hide}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  Please enter refresh token
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>}
                          <Col lg={12} md={12} sm={12} className="pe-4 pb-4">
                            <GenerateButton
                            disabled={apiKey &&(( apiKey.length === 0 || secretKey.length ===0)) ? true : false}
                              variant={
                                !rediected &&accessKey&&   accessKey.length === 0
                                  ? "outlined"
                                  : "contained"
                              }
                              className={
                                !rediected &&accessKey&&  accessKey.length === 0
                                  ? ""
                                  : "text-white"
                              }
                              onClick={() => getAuthCode()}
                            >
                              <Image
                                src={RefreshIcon}
                                height="20px"
                                className={
                                  !rediected &&accessKey&&  accessKey.length === 0
                                    ? " generate_button_icon px-2"
                                    : "px-2"
                                }
                              ></Image>
                              Generate Access Code
                            </GenerateButton>
                          </Col>
                          {(rediected && (accessKey === null || accessKey === "") )&& <><Col lg={6} md={6} sm={12} className="pe-4">
                            <Form.Group
                              className="pb-4 form_label"
                              controlId="authCode"
                            >
                              <Form.Label>Access Code<span className="text-danger">*</span> </Form.Label> 
                              <InputGroup
                                className="pb-2 form_label "
                                controlId="authCode"
                              >
                                <Form.Control
                                  className=" user_input_field border-end-0" required
                                  type={
                                    showHidden3 === false ? "password" : "text"
                                  }
                                  value={oAuthCode}
                                  onChange={(event) =>
                                    setOauthCode(event.target.value)
                                  }
                                  placeholder="Enter app secret"
                                />
                                <InputGroup.Text
                                  id="appSecret"
                                  className="bg-white my-input-group cursor_pointer"
                                  onClick={() => setShowHidden3(!showHidden3)}
                                >
                                  {showHidden3 === false ? (
                                    <Image
                                      src={Show}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  ) : (
                                    <Image
                                      src={Hide}
                                      height="20px"
                                      className="px-2"
                                    ></Image>
                                  )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  Please enter app secret 
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6} sm={12} className="pe-4 d-flex align-items-center"> 
                          <GenerateButton
                          disabled ={
                            oAuthCode.length !== 0 ? false: true
                          }
                          variant="outlined"
                              onClick={() => getRefreshToken()}
                            >
                              Fetch Refresh Token
                            </GenerateButton>
                            </Col></>}
                        </Row>
                      </div>
                    )}
                    {(selectedOption === null ||
                      selectedOption === undefined ||
                      selectedOption === "local_server") && (
                      <div className=" d-flex justify-content-start alert_box px-3 pt-3 border rounded m-4">
                        <p class="fw-bold fst-italic">Note : &nbsp;</p>
                        <p>
                          The local server is set as the default storage option
                          for recorded videos. To upload to the cloud, choose an
                          option provided above.
                        </p>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default StorageConfig;
