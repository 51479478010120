import React,{useEffect} from 'react'
import CommingSoon from '../../CommingSoon'
import { changeTitle } from "../../../../state/slices/header";
import { useDispatch } from 'react-redux';

const AutoAssign = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitle("Groups"));
}, [])
  return (
   <CommingSoon/>
  )
}

export default AutoAssign   