import axios from "./axios_interceptor";
import { get_jwt_token, loginRedirect } from "./helper_funtions";
export const getReportPlotData = async () => {
    try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
    }
      let url = `/audits/session-plot/`;
      const response = await axios.get(url,config);
      return { data: response.data, error: null };
    } catch (e) {
      return { data: null, error: e.response };
    }
  }
export const getMostActiveUsersList = async () => {
    try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
    }
      let url = `/audits/most-active-users/`;
      const response = await axios.get(url,config);
      return { data: response.data, error: null };
    } catch (e) {
      return { data: null, error: e.response };
    }
  }

  export const getCommandsData = async () => {
    try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
    }
      let url = `/audits/commands-chart/`;
      const response = await axios.get(url,config);
      return { data: response.data, error: null };
    } catch (e) {
      return { data: null, error: e.response };
    }
  }

  export const getLoginSuccessData = async () => {
    try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
    }
      let url = `/audits/login-success-chart/`;
      const response = await axios.get(url,config);
      return { data: response.data, error: null };
    } catch (e) {
      return { data: null, error: e.response };
    }
  }

  export const getMostUsedResourceList= async () => {
    try {
      const token = get_jwt_token();
      loginRedirect(token);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
    }
      let url = `/audits/most-used-resources/`;
      const response = await axios.get(url,config);
      return { data: response.data, error: null };
    } catch (e) {
      return { data: null, error: e.response };
    }
  }