import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getWebAppDetail } from '../../../../api/web_app';
import DropDownAction from '../../../common/DropDownAction/DropDownAction';
import AllocateWebAppToGroups from './AllocateWebAppToGroups';
import AllocateWebAppToUsers from './AllocateWebAppToUsers';
import AllocateWebAppToRoles from './AllocateWebAppToRoles';
import { changeTitle } from "../../../../state/slices/header";
import { useDispatch } from 'react-redux';


const MainAllocateWebApp = () => {
    const dispatch = useDispatch();
    const { web_app_id } = useParams();
    const [tabValue, setTabValue] = useState("users");
    const [webAppName, setWebAppName] = useState("");
    const actionOptions = [
        { label: "Users", value: "users" },
        { label: "Groups", value: "groups" },
        { label: "Roles", value: "roles" },
    ]
    const fetchWebAppData = async () => {
        const { data, error } = await getWebAppDetail({ web_app_id: web_app_id });
        if (data != null) {
            setWebAppName(data.web_app.app_name);
        }
    }

    useEffect(() => {
        dispatch(changeTitle("WebApps"));
        fetchWebAppData();
    }, [])
    return (
        <div>
            {tabValue === "groups" && <AllocateWebAppToGroups webAppName={webAppName} ActionComponent={< DropDownAction setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions} />} />}
            {tabValue === "users" && <AllocateWebAppToUsers webAppName={webAppName} ActionComponent={< DropDownAction setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions} />} />}
            {tabValue === "roles" && <AllocateWebAppToRoles webAppName={webAppName} ActionComponent={< DropDownAction setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions} />} />}
        </div>
    )
}

export default MainAllocateWebApp;
