import React from 'react'
import images from '../../../../utils/images'
import './NotAllowed.scss';
const NotAllowed = () => {
    return (
        <div className='d-flex flex-row justify-content-around align-items-center px-5'>
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <h2 className='not_allowed_heading'>
                    NOT <span className='highlight'>ALLOWED.</span>
                </h2>
                <p className='not_allowed_message'>
                    Your admin has not configured Multi-factor Authentication
                    methods for you yet. Send a <span className='highlight'>request</span> to your admin.
                </p>
            </div>
            <div className='d-none d-md-block'>
                <div>
                    <img src={images.NotAllowedMethods} alt="Not Allowed" className='mx-5 w-100'/>
                </div>
            </div>
        </div>
    )
}

export default NotAllowed
