import axios from './axios_interceptor';
import {get_jwt_token, loginRedirect} from './helper_funtions'

export const updateProductSettings = async (data) => {

    try {
        const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/customer/urlsettings/`
        const response = await axios.put(url, data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const getProductSettings = async () => {

    try {
        const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/customer/urlsettings/`
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const registerAdmin = async () => {

    try {
        let url = `/register/`
        const response = await axios.get(url);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const getEndUserProductSettings = async () => {

    try {
        const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/customer/end-user-settings/`
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const getMiniOrangeUIs = async () => {
    try{
        const response = await axios.get("/branding/miniOrange-logo/?name=favicon");
        return {data:response.data,error:null}
    }catch(e){
        return {data:null,error:e.response.data.errors}
    }
};