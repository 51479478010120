import axios from './axios_interceptor';
import {get_jwt_token, loginRedirect} from './helper_funtions'

export const fetchQrCodeAndSecret = async () => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/mfa/authenticator/`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const verifyOtpSaveConfig = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/mfa/authenticator/`;
        const response = await axios.post(url,data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}


export const fetchMiniorangeMFAConfiguration = async (method) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/mfa/configurations/?method=${method}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const miniorangeChallenge = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/mfa/challenge/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const miniorangeMFAConfiguration = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/mfa/configurations/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const miniorangePolling = async (method, txid, register = 1) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/mfa/polling/?method=${method}&txid=${txid}&register=${register}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const getMFAMethodList = async () => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/mfa/method-list/`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}


export const enableMFAMethod = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/mfa/enable-method/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const enableAdminMfa = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/mfa/enable-admin-mfa/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const getEndUserMFAConfigurations = async () => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/mfa/end-user-configurations/`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const setEndUserMFAConfigurations = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/mfa/end-user-configurations/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const challengeMFAForResourceAccess = async (user_id) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/autheticate/mfa-over-resource-access/?user_id=${user_id}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const verifyMFAForResourceAccess = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/autheticate/mfa-over-resource-access/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}