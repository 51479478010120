import axios from './axios_interceptor';
import { get_jwt_token } from "./helper_funtions";
import { loginRedirect } from './helper_funtions';

export const ContactUsPost=async(args)=>{
    try{
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
    const url = `/customer/contact-us/`;
    const response = await axios.post(url,args,config);
    return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}