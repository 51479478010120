import axios from "./axios_interceptor";
import {get_jwt_token, loginRedirect} from './helper_funtions'
export const getbrandingLogo = async () => {
  const url = `/branding/?name=logo`;
  try {
    const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const brandingLogo = async (formField) => {
  const url = "/branding/";
  try {
    const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`
            },
        };
    const response = await axios.post(url, formField, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getbrandingFavicon = async () => {
  const url = "/branding/?name=favicon";
  try {
    const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const brandingFavicon = async (formField) => {
  const url = "/branding/";
  try {
    const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`
            },
        };
    const response = await axios.post(url, formField, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getOrganisationName = async () => {
  const url = "/customer/organisation-name/";
  try {
    const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const OrganisationName = async (formField) => {
  const url = "/customer/organisation-name/";
  try {
    const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
    const response = await axios.post(url, formField, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};