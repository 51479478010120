import React from "react";
import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import images from "../../../utils/images.js";
import GetCapabilities from "../../../utils/getCapabilities.js";

function Customizations(props) {
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const { title } = props;
  const dispatch = useDispatch();
  const handleTitle = async () => {
    dispatch(changeTitle("Customizations"));
  };
  return (
    <Accordion.Item
      eventKey="Customizations"
      className={
        title === "Customizations" ? "sidebar-button-border " : ""
      }
    >
      <Accordion.Header>
        <img
          className="mx-3 filter-active"
          src={require("./icons/customization.svg").default}
          height="22px"
          width="22px"
        />
        Customization
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup variant="flush" className="sidebar-list">
          {isCapable && isCapable.branding.edit_branding_setting &&(<ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/dashboard/Branding"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Branding</div>
            </NavLink>
          </ListGroup.Item>)}

         {isCapable && isCapable.branding.manage_email_provider &&( <ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/dashboard/configure-smtp"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Custom Email Provider</div>
            </NavLink>
          </ListGroup.Item>)}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default Customizations;
