import React from "react";
import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import images from "../../../utils/images.js";

function LiveSession(props) {
  const { isEndUser, title } = props;
  const dispatch = useDispatch();
  const handleTitle = async () => {
    dispatch(changeTitle("Live"));
  };
  return (
    <Accordion.Item eventKey="Live"  className={title === "Live"?"sidebar-button-border ": ""}>
      <Accordion.Header >
        <img
          className="mx-3 filter-active"
          src={require("./icons/disc.svg").default}
          alt="live-session-icon"
        ></img>
        Sessions
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup variant="flush" className="sidebar-list">
          <ListGroup.Item className="py-0 pe-0 ps-2 ">
            <NavLink
              to="/audit-dashboard/live"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Real-Time Sessions</div>
            </NavLink>
          </ListGroup.Item>
          <ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/audit-dashboard/recordings"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Recorded Sessions</div>
            </NavLink>
          </ListGroup.Item>
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
}
export default LiveSession;
