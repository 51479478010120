import React from "react";

const CommingSoon = () => {
  return (
    <div >
      <img
        className="mx-2 filter-active"
        src={require("../../images/CommingSoon.svg").default}
        alt="dashboard-icon"
        height="600"
      />
    </div>
  );
};

export default CommingSoon;
