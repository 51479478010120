import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getRole } from "../../../../api/role";
import { changeTitle } from "../../../../state/slices/header";
import DropDownAction from "../../../common/DropDownAction/DropDownAction";
import AllocateRoleToApp from "./AllocateRoleToApp";
import AllocateRoleToAsset from "./AllocateRoleToAsset";
import AllocateRoleToWebApp from "./AllocateRoleToWebApp";

const MainAllocateRole = () => {
  const dispatch = useDispatch();
  const { role_id } = useParams();
  const [tabValue, setTabValue] = useState("apps");
  const [roleName, setRoleName] = useState("");
  const actionOptions = [
    { label: "Apps", value: "apps" },
    { label: "Assets", value: "assets" },
    { label: "Web Apps", value: "web_apps" },
  ];
  const fetchRolesData = async () => {
    const { data, error } = await getRole({role_id});
    if (data != null) {
      setRoleName(data.role_name);
    }
    if (error != null) {
    }
  };

  useEffect(() => {
    dispatch(changeTitle("AllocateApps"));
    fetchRolesData();
  }, []);
  return (
    <div>
      {tabValue === "apps" && (
        <AllocateRoleToApp
          roleName={roleName}
          ActionComponent={
            <DropDownAction
              setTabValue={setTabValue}
              tabValue={tabValue}
              actionOptions={actionOptions}
            />
          }
        />
      )}
      {tabValue === "assets" && (
        <AllocateRoleToAsset
          roleName={roleName}
          ActionComponent={
            <DropDownAction
              setTabValue={setTabValue}
              tabValue={tabValue}
              actionOptions={actionOptions}
            />
          }
        />
      )}
      {tabValue === "web_apps" && (
        <AllocateRoleToWebApp
          roleName={roleName}
          ActionComponent={
            <DropDownAction
              setTabValue={setTabValue}
              tabValue={tabValue}
              actionOptions={actionOptions}
            />
          }
        />
      )}
    </div>
  );
};

export default MainAllocateRole;
