import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getWebAppDetail } from '../../../../api/web_app';
import DropDownAction from '../../../common/DropDownAction/DropDownAction';
import DeAllocateWebAppFromGroup from './DeAllocateWebAppFromGroups';
import DeAllocateWebAppFromUsers from './DeAllocateWebAppFromUsers';
import DeAllocateWebAppFromRoles from './DeAllocateWebAppFromRoles';
import { changeTitle } from "../../../../state/slices/header";
import { useDispatch } from 'react-redux';

const MainDeAllocateWebApp = () => {
    const dispatch = useDispatch();
    const { web_app_id } = useParams();
    const [tabValue, setTabValue] = useState("users")
    const [webAppName, setWebAppName] = useState("");
    const actionOptions = [
        { label: "Users", value: "users" },
        { label: "Groups", value: "groups" },
        { label: "Roles", value: "roles" },
    ]
    const fetchWebAppData = async () => {
        const { data, error } = await getWebAppDetail({ web_app_id: web_app_id });
        if (data != null) {
            setWebAppName(data.web_app.app_name);
        }
        if (error != null) {

        }
    }
    useEffect(() => {
        dispatch(changeTitle("WebApps"));
        fetchWebAppData();
    }, []);
    return (
        <div>
            {tabValue === "groups" && <DeAllocateWebAppFromGroup webAppName={webAppName} ActionComponent={< DropDownAction setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions} />} />}
            {tabValue === "users" && <DeAllocateWebAppFromUsers webAppName={webAppName} ActionComponent={< DropDownAction setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions} />} />}
            {tabValue === "roles" && <DeAllocateWebAppFromRoles webAppName={webAppName} ActionComponent={< DropDownAction setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions} />} />}
        </div>
    )
}

export default MainDeAllocateWebApp;
