import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserDetails } from "../../../../api/users";
import { changeTitle } from "../../../../state/slices/header";
import DropDownAction from "../../../common/DropDownAction/DropDownAction";
import DeAllocateUserFromApp from "./DeAllocateUserFromApps";
import DeAllocateUserToAssets from "./DeAllocateUserFromAssets";
import DeAllocateUserFromGroups from "./DeAllocateUserFromGroups";
import GetCapabilities from "../../../../utils/getCapabilities";
import DeAllocateUserFromWebApp from "./DeAllocateUserFromWebApp";

const MainDeAllocateUser = () => {
  const dispatch = useDispatch();
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const { user_id } = useParams();
  const [tabValue, setTabValue] = useState("apps");
  const [username, setUsername] = useState("");
  const actionOptions = [
    isCapable && isCapable.apps.view && { label: "Apps", value: "apps" },
    isCapable && isCapable.assets.view && { label: "Assets", value: "assets" },
    isCapable && isCapable.web_apps.view && { label: "Web Apps", value: "web_apps" },
  ];
  const fetchUsersData = async () => {
    const { data, error } = await getUserDetails(user_id);
    if (data != null) {
      setUsername(data.username);
    }
    if (error != null) {
    }
  };

  useEffect(() => {
    dispatch(changeTitle("DeAllocateApps"));
    fetchUsersData();
  }, []);
  return (
    <div>
      {tabValue === "apps" && (
        <DeAllocateUserFromApp
          userName={username}
          ActionComponent={
            <DropDownAction
              setTabValue={setTabValue}
              tabValue={tabValue}
              actionOptions={(isCapable && (isCapable.apps.view || isCapable.assets.view)) ?  actionOptions:null}
            />
          }
        />
      )}
      {tabValue === "assets" && (
        <DeAllocateUserToAssets
          userName={username}
          ActionComponent={
            <DropDownAction
              setTabValue={setTabValue}
              tabValue={tabValue}
              actionOptions={(isCapable && (isCapable.assets.view || isCapable.assets.view)) ?  actionOptions:null}
            />
          }
        />
      )}
      {tabValue === "web_apps" && (
        <DeAllocateUserFromWebApp
          userName={username}
          ActionComponent={
            <DropDownAction
              setTabValue={setTabValue}
              tabValue={tabValue}
              actionOptions={(isCapable && (isCapable.web_apps.view || isCapable.web_apps.view)) ?  actionOptions:null}
            />
          }
        />
      )}
    </div>
  );
};

export default MainDeAllocateUser;
