import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { getMyAssetDetail } from '../../../../api/asset';
import DropDownAction from '../../../common/DropDownAction/DropDownAction';
import DeAllocateAssetFromGroup from './DeAllocateAssetFromGroup';
import DeAllocateAssetFromUsers from './viewusers';
import DeAllocateAssetFromRole from './DeallocateAssetFromRole';
import { changeTitle } from "../../../../state/slices/header";
import { useDispatch } from 'react-redux';

const MainDeAllocateAsset = () => {
    const dispatch = useDispatch();
    const {asset_id} = useParams();
    const [tabValue, setTabValue] = useState("users")
    const [assetName, setAssetName] = useState("");
    const actionOptions = [
        { label: "Users", value: "users" },
        { label: "Groups", value: "groups" },
        { label: "Roles", value: "roles" },
    ]
    const fetchAssetData = async () => {
        const {data, error} = await getMyAssetDetail({asset_id: asset_id});
        if(data != null) {
            setAssetName(data.asset.asset_name);
        }
        if (error != null) {
            
        }
    } 
    useEffect(() => {
        dispatch(changeTitle("Assets"));
        fetchAssetData();
    },[])
    return (
        <div>
            {tabValue === "groups" && <DeAllocateAssetFromGroup assetName={assetName} ActionComponent={< DropDownAction setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions}/>} />}
            {tabValue === "users" && <DeAllocateAssetFromUsers assetName={assetName} ActionComponent={< DropDownAction setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions}/>} />}
            {tabValue === "roles" && <DeAllocateAssetFromRole assetName={assetName} ActionComponent={< DropDownAction setTabValue={setTabValue} tabValue={tabValue} actionOptions={actionOptions}/>} />}
        </div>
    )
}

export default MainDeAllocateAsset
