import axios from './axios_interceptor';
import {get_jwt_token, loginRedirect} from './helper_funtions'


export const sendEventStream = async (data) => {
    try {
        const token = get_jwt_token()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/audits/session-recording/`
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
