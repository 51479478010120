import React, { } from 'react'
import Form from "react-bootstrap/Form";
import '../../DatabasePolicyList/DatabasePolicy.scss';
import '../../AddDatabasePolicy/AddDatabasePolicy.scss';
import { Tooltip } from '@mui/material';
import Images from '../../../../../../utils/images';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
const AdvanceSection = ({ action, selectedAutoCommitConditions, setSelectedAutoCommitConditions, selectedTransactionCommands, setSelectedTransactionCommands, setAction, resourceAction, setResourceAction, commandsChoice, setCommandsChoice, commands, setCommands }) => {
    const selectStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            minWidth: "200px",
            paddingTop: "3px",
            paddingBottom: "3px",
            borderColor: "#ced4da",
            boxShadow: "none",
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#717171",
            ':hover': {
                borderColor: "#ced4da",
            }
        }),
        option: (styles) => ({
            ...styles,
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
        }),
    }
    let transaction_commands = [
        {
            "label": "UPDATE",
            "value": "UPDATE",
        },
        {
            "label": "DELETE",
            "value": "DELETE"
        },
        {
            "label": "INSERT",
            "value": "INSERT"
        }
    ]
    let auto_commit_conditions =[
        {
            "label" : "row(s) affected are less than 2",
            "value" : "row(s) affected are less than 2" 
        }
    ]

    return (
        <div id="database_policy_advance">
            {setSelectedTransactionCommands && <div className='pt-0 pb-3 border-bottom'>
                <div>
                    <h3 className='mb-0 advance_heading'>
                        Transaction Control
                    </h3>
                    <p className='advance_description'>
                        This decides whether the executed commands should be transactional or not.
                    </p>
                </div>
                <div className='d-flex flex-column'>
                    <Form.Group className="mb-3 d-flex justify-content-start align-items-center" controlId="formPlaintextAssetType">
                        <Form.Label column md={2} className="input_label ms-2">
                            Commands
                        </Form.Label>
                        <Select
                            isMulti
                            className="systemuser_select"
                            classNamePrefix="asset"
                            isClearable={true}
                            isSearchable={true}
                            name="systemuser_select"
                            value={selectedTransactionCommands}
                            onChange={(selectedOptions) => { setSelectedTransactionCommands(selectedOptions) }}
                            options={transaction_commands}
                            styles={selectStyles}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex justify-content-start align-items-center" controlId="formPlaintextAssetType">
                        <Form.Label column md={2} className="input_label ms-2">
                            Auto Commit Conditions
                        </Form.Label>
                        <Select
                            isMulti
                            className="systemuser_select"
                            classNamePrefix="asset"
                            isClearable={true}
                            isSearchable={true}
                            name="systemuser_select"
                            value={selectedAutoCommitConditions}
                            onChange={(selectedOptions) => { setSelectedAutoCommitConditions(selectedOptions) }}
                            options={auto_commit_conditions}
                            styles={selectStyles}
                        />
                    </Form.Group>
                </div>
            </div>}
            <div className='pt-0 pb-3 mt-4 border-bottom'>
                <div>
                    <h3 className='mb-0 advance_heading'>
                        App access Action
                    </h3>
                    <p className='advance_description'>
                        This decides whether the application will be accessible by the user.
                    </p>
                </div>
                <div className='d-flex flex-row'>
                    <div className='d-flex justify-content-start align-items-center'>
                        <Form.Check
                            inline
                            label="ALLOW"
                            checked={action === "ALLOW" ? true : false}
                            onClick={() => setAction("ALLOW")}
                            className='ps-2  me-1 advance_checkbox'
                            name="ALLOW"
                            type="radio"
                            id="ALLOW-radio-1"
                        />
                        <Tooltip title="User will be able to access the resource directly." placement="top-end">
                            <img
                                src={Images.InfoIcon}
                                alt="info"
                                width="15"
                                height="15"
                                className=" cursor_pointer" />
                        </Tooltip>
                    </div>
                    <div className='d-flex justify-content-start align-items-center'>
                        <Form.Check
                            inline
                            label="ELEVATED_REQUEST"
                            checked={action === "ELEVATED_REQUEST" ? true : false}
                            onClick={() => setAction("ELEVATED_REQUEST")}
                            className='ps-2  ms-5 me-1 advance_checkbox'
                            name="ELEVATED_REQUEST"
                            type="radio"
                            id="ELEVATED_REQUEST-radio-2"
                        />
                        <Tooltip title="Users will have privilege to grant access for the resource to themselves." placement="top-end">
                            <img
                                src={Images.InfoIcon}
                                alt="info"
                                width="15"
                                height="15"
                                className=" cursor_pointer" />
                        </Tooltip>
                    </div>
                    <div className='d-flex justify-content-start align-items-center'>

                        <Form.Check
                            inline
                            label="REQUEST"
                            checked={action === "REQUEST" ? true : false}
                            onChange={() => setAction("REQUEST")}
                            className='ps-2 ms-5 me-1 advance_checkbox'
                            name="REQUEST"
                            type="radio"
                            id="REQUEST-radio-2"
                        />
                        <Tooltip title="Users will have privilige to request for access of the resource from Admin." placement="top-end">
                            <img
                                src={Images.InfoIcon}
                                alt="info"
                                width="15"
                                height="15"
                                className=" cursor_pointer" />
                        </Tooltip>
                    </div>
                    <div className='d-flex justify-content-start align-items-center'>
                        <Form.Check
                            inline
                            label="BLOCK"
                            checked={action === "BLOCK" ? true : false}
                            onChange={() => setAction("BLOCK")}
                            className='ps-2 ms-5 me-1 advance_checkbox'
                            name="BLOCK"
                            type="radio"
                            id="BLOCK-radio-2"
                        />
                        <Tooltip title="Users will not be able to access the resource." placement="top-end">
                            <img
                                src={Images.InfoIcon}
                                alt="info"
                                width="15"
                                height="15"
                                className="cursor_pointer" />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className='pt-3 pb-3 border-bottom'>
                <div>
                    <h3 className='mb-0 advance_heading'>
                        Default Resource Action
                    </h3>
                    <p className='advance_description'>
                        This decides the action to be taken if any policy is not found for a commands.
                    </p>
                </div>
                <div className='d-flex flex-row justify-content-start align-items-center'>
                    <div className='d-flex justify-content-start align-items-center'>

                        <Form.Check
                            inline
                            label="ALLOW"
                            checked={resourceAction === "ALLOW" ? true : false}
                            onChange={() => setResourceAction("ALLOW")}
                            className='ps-2 me-1 advance_checkbox'
                            name="ALLOW-R"
                            type="radio"
                            id="ALLOW-R-radio-6"
                        />
                        <Tooltip title="This will allow the commad to execute." placement="top-end">
                            <img
                                src={Images.InfoIcon}
                                alt="info"
                                width="15"
                                height="15"
                                className=" cursor_pointer" />
                        </Tooltip>
                    </div>
                    <div className='d-flex justify-content-start align-items-center'>
                        <Form.Check
                            inline
                            label="DENY"
                            checked={resourceAction === "DENY" ? true : false}
                            onChange={() => setResourceAction("DENY")}
                            className='ps-2 ms-5 me-1 advance_checkbox'
                            name="DENY-R"
                            type="radio"
                            id="DENY-R-radio-2"
                        />
                        <Tooltip title="This will deny the command to execute." placement="top-end">
                            <img
                                src={Images.InfoIcon}
                                alt="info"
                                width="15"
                                height="15"
                                className="cursor_pointer" />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className='pt-3 pb-3'>
                <div>
                    <h3 className='mb-0 advance_heading'>
                        Commands
                    </h3>
                    <p className='advance_description'>
                        Enter the commands you want to whitelist or blacklist
                    </p>
                </div>
                <div className='d-flex flex-row justify-content-start align-items-center'>
                    <div className='d-flex justify-content-start align-items-center'>

                        <Form.Check
                            inline
                            label="WHITELIST"
                            checked={commandsChoice === "WHITELIST" ? true : false}
                            onChange={() => setCommandsChoice("WHITELIST")}
                            className='ps-2 mb-3 me-1 advance_checkbox'
                            name="WHITELIST-C"
                            type="radio"
                            id="WHITELIST-C-radio-3"
                        />
                        <Tooltip title="All the mentioned commands in textarea will be whitelisted." placement="top-end">
                            <img
                                src={Images.InfoIcon}
                                alt="info"
                                width="15"
                                height="15"
                                className=" mb-3 cursor_pointer" />
                        </Tooltip>
                    </div>
                    <div className='d-flex justify-content-start align-items-center'>
                        <Form.Check
                            inline
                            label="BLACKLIST"
                            checked={commandsChoice === "BLACKLIST" ? true : false}
                            onChange={() => setCommandsChoice("BLACKLIST")}
                            className='ps-2 mb-3 ms-5 me-1 advance_checkbox'
                            name="BLACKLIST-C"
                            type="radio"
                            id="BLACKLIST-radio-4"
                        />
                        <Tooltip title="All the mentioned commands in textarea will be blacklisted." placement="top-end">
                            <img
                                src={Images.InfoIcon}
                                alt="info"
                                width="15"
                                height="15"
                                className="mx-0 mb-3 cursor_pointer" />
                        </Tooltip>
                    </div>
                </div>
                <Form.Group className="mb-3" controlId="commands_textarea">
                    <Form.Control
                        as="textarea"
                        className='form_input'
                        rows={3}
                        value={commands}
                        onChange={(event) => setCommands(event.target.value)}
                        placeholder="Enter your commands here"
                    />
                </Form.Group>
                <p className='advance_description'>commands should be ":" sepreated</p>
            </div>
        </div>
    )
}

export default AdvanceSection
