import axios from './axios_interceptor';
import { get_jwt_token } from "./helper_funtions";
import { loginRedirect } from './helper_funtions';

export const postExternalIdpData = async (oauthserver,
  applicationName,
  authEndpoint,
  tokenEndpoint,
  clientid,
  clientSecret,
  enduserinfoEndpoint,
  scopes,
  redirectURL,
  rows,
  rolesMapping
) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const args = {
      'oauthserver': oauthserver,
      'applicationname': applicationName,
      'authendpoint': authEndpoint,
      'tokenendpoint': tokenEndpoint,
      'clientid': clientid,
      'clientsecret': clientSecret,
      'enduserinfo': enduserinfoEndpoint,
      'scope': scopes,
      'redirecturi': redirectURL,
      'attributemapping': rows,
      'ruleBasedRoleMapping': rolesMapping
    }
    const url = `/sso/sso-info/`;
    const response = await axios.post(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
}
export const getExternalIdpData = async () => {
  try {
    const token = get_jwt_token();
    loginRedirect(token)
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const url = `/sso/sso-info/`
    const response = await axios.get(url, config);
    return { data: response.data, error: null }
  } catch (e) {
    return { data: null, error: e.response.data.errors }
  }
}

export const GetMinioAttributeData = async(oauthappname)=>{
  try{
    const token=get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const url = `/sso/sso-minio/?oauthappname=${oauthappname}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.error }
  }
}

export const getConfiguredIdp=async(oauthappname)=>{
  try{
    const token=get_jwt_token();
    loginRedirect(token)
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const url = `/sso/sso-configured/?oauthname=${oauthappname}`
    const response = await axios.get(url, config);
    return { data: response.data, error: null }
  } catch (e) {
    return { data: null, error: e.response.data.errors }
  }
}


export const PostSSOption = async (enable) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const args = {
      "ssoenabled": enable,
    }
    const url = `/sso/sso-enabled/`;
    const response = await axios.post(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data };
  }
}

export const postMiniOCloudData = async (usernameEmail, password, minioDomainUrl) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const args = {
      "email": usernameEmail,
      "password": password,
      "url": minioDomainUrl
    }
    const url = `/sso/miniorange-config/`;
    const response = await axios.post(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
}
export const getMiniOCloudData = async () => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const url = `/sso/miniorange-config/`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
}

export const postTestExternalIdpData = async (
  oauthserver,
  applicationName,
  authEndpoint,
  tokenEndpoint,
  clientid,
  clientSecret,
  enduserinfoEndpoint,
  scopes,
  redirectURL
) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const args = {
      'oauthserver': oauthserver,
      'applicationname': applicationName,
      'authendpoint': authEndpoint,
      'tokenendpoint': tokenEndpoint,
      'clientid': clientid,
      'clientsecret': clientSecret,
      'enduserinfo': enduserinfoEndpoint,
      'scope': scopes,
      'redirecturi': redirectURL,
    }
    const url = `/sso/save-test-sso/`;
    const response = await axios.post(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
}
export const resetMiniorangeCloudConfiguration = async (oauthappname
) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      data:{
        "oauthappname":oauthappname
      }
    }
    const url = `/sso/miniorange-config/`;
    const response = await axios.delete(url, config);
    return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const postTestMiniOrangeIdpData = async (
applicationName
)=>{
  try{
    const token=get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const args={
        'applicationname':applicationName,
      }
    const url = `/sso/save-test-sso-miniorange/`;
    const response = await axios.post(url, args, config);
    return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const saveMiniOrangeIDPData = async (arguments_obj
)=>{
  try{
      const token=get_jwt_token();
  loginRedirect(token);const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }
    const args=arguments_obj
    const url = `/sso/sso-minio/`;
    const response = await axios.post(url, args, config);
    return {data: response.data,error:null};
  }catch(e){
    return {data: null,error: e.response};
  }
}