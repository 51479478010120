import axios from './axios_interceptor';
import {get_jwt_token, loginRedirect} from './helper_funtions'

export const getStorageOptions = async (storage_type) => {
    try {
        let token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let url = `/customer/storage-configurations/?storage_type=${storage_type}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null}
    
    } catch (e) {
        return {data: null, error: e.response.data.errors}
    }
}

export const updateStorageOptions = async (args) => {
    try {
        let token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let url = `/customer/storage-configurations/`;
        const response = await axios.put(url,args, config);
        return { data: response.data, error: null}
    
    } catch (e) {
        return {data: null, error:  e.response.data.errors}
    }
}

export const getDropboxRefreshToken = async (oAuthCode,Oauthkey,  Oauthsecret) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const body = new URLSearchParams();
        body.append('code', oAuthCode);
        body.append('grant_type', 'authorization_code');
        const config = {
            auth:{
                username: Oauthkey,
                password: Oauthsecret
        },
        };
        const url = `https://api.dropbox.com/oauth2/token` ;
        const response = await axios.post(url, body, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data};
    }
};