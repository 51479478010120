import axios from './axios_interceptor';
import { get_jwt_token, loginRedirect } from './helper_funtions';

export const recordingList = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/audits/session-recording-list/?page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const generateToken = async () => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/autheticate/audit_authenticate/`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}