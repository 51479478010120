import React, { Fragment, useState, useEffect } from "react";
import "./ForgotPassword.scss";
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { forgotPassword } from "../../../api/users";
import { showError, showSuccess } from "../../../utils/showMessage";


const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const forgotPasswordFormHandler = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            const { data, error } = await forgotPassword({ email: email });
            if (data !== null) {
                showSuccess(data.message);
                setShowMessage(true);
            }
            if (error !== null) {
                showError(error)
            }
            setLoading(false);
        }
    };

    return (
        <>
            <div className="forgot_password_container">
                <div className="password_container d-flex justify-content-center align-items-center w-100 h-100">
                    <div className="d-flex flex-column justify-content-between align-items-center password_container_body">
                        <div className="forgot_password_heading_container">
                            {!showMessage && <h2 id="forgot_password_heading">Forgot Password</h2>}
                        </div>
                        {showMessage && <div className="alert alert-info">A reset link has been sent to your registered email. Please use it to reset your password.</div>}
                        <Form noValidate validated={validated} onSubmit={forgotPasswordFormHandler} className="add_asset_form w-100">
                            <Stack gap={1}>
                                <Form.Group className="mb-3" controlId="formPlaintextName">
                                    <Form.Control
                                        required
                                        className="form_input_field forgot_input_field"
                                        type="text"
                                        value={email}
                                        onChange={event => setEmail(event.target.value)}
                                        placeholder="Email/Username"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter username or email.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Link to={window.location.origin} className="back_to_login_link">Back to Login</Link>
                                <Button as="input" type="submit" value="Reset Password" className="form_submit_button" disabled={loading} />
                            </Stack>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;