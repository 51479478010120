import React from "react";
import Checkbox from '@mui/material/Checkbox';
import { PostSSOption } from "../../../api/sso";
import { showErrorMsg, showSuccess } from '../../../utils/showMessage';
import Form from "react-bootstrap/Form";

const EnableSSO =({enabled,setEnabled})=>{
    const sendEnableChoice=async(enable)=>{
        const {data,error}=await PostSSOption(enable)
        if(data !== null){
            showSuccess(data.message)
          }
          else{
            showErrorMsg(error.error)
          }
    }
    const enableDisableSSOHandle=()=>{
        setEnabled(o=>!o)
        sendEnableChoice((!enabled).toString())
    }
    return(<>
        <div className="enable-element ms-5 my-3 d-flex flex-row justify-content-between align-items-start">
            <div>
                <div className="mb-2"><span className="enable-heading">Enable SSO for PAM</span></div>
                <div className="d-flex flex-row justify-content-start align-items-center">
                    <div>
                    <Form.Check
                    type="switch"
                    checked={enabled}
                    id="SSO"
                    onChange={enableDisableSSOHandle}
                  />
                    </div>
                    <div className="enable-desc">Enable Single Sign On for PAM</div>
                </div>
            </div>
        </div>
    </>)
}
export default EnableSSO