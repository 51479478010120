import axios from './axios_interceptor';
import { get_jwt_token, loginRedirect } from './helper_funtions';

  
export const groupList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/groups/groups/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const addGroup = async (data) => {
    try {
        const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/groups/group/`;
        const response = await axios.post(url,data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const deleteGroups = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: data
        };
        const url = `/groups/group/`;
        const response = await axios.delete(url,config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const getGroupDetails = async ({group_id}) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/groups/group/?group_id=${group_id}`;
        const response = await axios.get(url,config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const updateGroup = async ({group_id, updatedGroup}) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/groups/group/?group_id=${group_id}`;
        const response = await axios.put(url,updatedGroup, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const nonAssignedUsers = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/groups/assign-users/?group_id=${data.group_id}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const assignUsersToGroup = async (data) => {
    try {
        const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/groups/assign-users/`;
        const response = await axios.post(url,data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const assignedUsers = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/groups/assigned-users/?group_id=${data.group_id}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const removeUsersFromGroup = async (data) => {
    try {
        const token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/groups/assigned-users/`;
        const response = await axios.post(url,data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}