import axios from './axios_interceptor';
import {get_jwt_token, loginRedirect} from './helper_funtions'



export const getLicense = async (args) => {
    try {
        let token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let url = `/customer/license/`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null}

    } catch (e) {
        return {data: null, error: e.response.data.errors}
    }
}


export const updateLicense = async (args) => {
    try {
        let token = get_jwt_token()
        loginRedirect(token);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let url = `/customer/license/`;
        const response = await axios.put(url, args, config);
        return { data: response.data, error: null}

    } catch (e) {
        return {data: null, error: e.response.data.errors}
    }
}