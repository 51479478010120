import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { getAuthenticatedUserProfile } from '../../../api/users';
import { authenticateUser } from '../../../state/slices/user';
import { store } from '../../../state/store';
import Loader from '../../common/Loader/Loader';
const ConsumeToken = () => {
  const { access_token } = useParams();
  const navigate = useNavigate();
  const getUserProfile = async () => {
    const { data } = await getAuthenticatedUserProfile();
    if (data !== null) {
      store.dispatch(authenticateUser(data));
      navigate('/dashboard')
    }
  }

  useEffect(() => {
    localStorage.setItem('jwt', access_token);
    getUserProfile();
  }, [])
  return (
    <>
      <Loader loading={true} bg_papper={false} />
    </>
  )
}

export default ConsumeToken;
